import { lazy } from 'react';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const MyGoals = lazy(() => import('./myGoals/MyGoals'));
const MyDirectReports = lazy(() => import('./myDirectReports/MyDirectReports'));
const Announcements = lazy(() => import('./announcements/Announcements'));
const EnphaseGoals = lazy(() => import('./EnphaseGoals/EnphaseGoals'));
const DelegateGoals = lazy(() => import('./delegateGoals/DelegateGoals'));
const Reports = lazy(() => import('./reports/Reports'));
const ReviewCycle = lazy(() => import('./reviewCycle/ReviewCycle'));
const AddAnnouncements = lazy(() => import('./addAnnouncements/AddAnnouncements'));
const Users = lazy(() => import('./users/Users'));
const UserTabs = lazy(() => import('./users/UserTabs'));
const Documentation = lazy(() => import('./documentation/Documentation'));
const Admin = lazy(() => import('./admin/Admin'));
const AdminTabs = lazy(() => import('./admin/AdminTabs'));
const releaseNotes = lazy(() => import('./releaseNotes/ReleaseNotes'));
const ExecutiveDashboard = lazy(() => import('./executiveDashboard/ExecutiveDashboard'));
const MyExecutiveReports = lazy(() => import('./myExecutiveReports/MyExecutiveReports'));
const MyAPRFeedback = lazy(() => import('./aprfeedback/MyAPRFeedback'));
const MyReportsFeedback = lazy(() => import('./aprfeedback/MyReportsFeedback'));
const FeedbackExportPage = lazy(() => import('./aprfeedback/FeedbackExport'));
const ActiveSessions = lazy(() => import('./activeSessions/ActiveSessions'));

export {
    Dashboard,
    MyGoals,
    MyDirectReports,
    Announcements,
    EnphaseGoals,
    DelegateGoals,
    Reports,
    ReviewCycle,
    AddAnnouncements,
    Users,
    UserTabs,
    Documentation,
    Admin,
    AdminTabs,
    releaseNotes,
    ExecutiveDashboard,
    MyExecutiveReports,
    MyAPRFeedback,
    MyReportsFeedback,
    FeedbackExportPage,
    ActiveSessions,
};
