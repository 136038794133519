import React, { useEffect } from 'react';
import base64 from 'base64-js';
import qs from 'qs';
import { Loader } from '../index';
import axios from 'axios';

const Auth: React.FC = () => {
    // const createCodeVerifier = (size: number) => {
    //     const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.~';
    //     const charsetIndexBuffer = new Uint8Array(size);

    //     for (let i = 0; i < size; i += 1) {
    //         charsetIndexBuffer[i] = (Math.random() * charset.length) | 0;
    //     }

    //     const randomChars = [];
    //     for (let i = 0; i < charsetIndexBuffer.byteLength; i += 1) {
    //         const index = charsetIndexBuffer[i] % charset.length;
    //         randomChars.push(charset[index]);
    //     }
    //     return randomChars.join('');
    // };

    // const textEncodeLite = (str: string) => {
    //     const charCodeBuffer = new Uint8Array(str.length);
    //     for (let i = 0; i < str.length; i++) {
    //         charCodeBuffer[i] = str.charCodeAt(i);
    //     }
    //     return charCodeBuffer;
    // };

    // const urlSafe = (buffer: Uint8Array) => {
    //     const encoded = base64.fromByteArray(new Uint8Array(buffer));

    //     return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    // };

    // const createCodeChallenge = (codeVerifier: string) => {
    //     // console.log('Code verifier inside Code challenge ' + codeVerifier);
    //     if (typeof window !== 'undefined' && !!window.crypto && !!window.crypto.subtle) {
    //         return new Promise((resolve, reject) => {
    //             const codeVerifierCharCodes = textEncodeLite(codeVerifier);
    //             crypto.subtle.digest('SHA-256', codeVerifierCharCodes).then(
    //                 (hashedCharCodes) => resolve(urlSafe(new Uint8Array(hashedCharCodes))),
    //                 (error) => reject(error),
    //             );
    //         });
    //     }
    // };

    // const PKCEAuthCodeFirstStep = async () => {
    //     const codeVerifier = createCodeVerifier(50);
    //     // console.log('Coded Verifier : ' + codeVerifier);
    //     localStorage.setItem('code_verifier', codeVerifier);
    //     const codeChallenge = await createCodeChallenge(codeVerifier);
    //     const queryParams = {
    //         client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
    //         code_challenge: codeChallenge,
    //         redirect_uri: `${process.env.REACT_APP_API_HOST}/auth/callback`,
    //         code_challenge_method: 'S256',
    //         response_type: 'code',
    //         scope: 'openid',
    //     };
    //     const paramsString = qs.stringify(queryParams);
    //     const url = `${process.env.REACT_APP_OIDC_URL}/authorize?${paramsString}`;

    //     return url;
    // };

    const handleSignIn = async () => {
        try {
            // const firstStep = await PKCEAuthCodeFirstStep();
            // debugger;
            const response = await axios.get(`${process.env.REACT_APP_API_HOST}/v1/auth/PKCEAuthCodeFirstStep`, {
                withCredentials: true,
            });
            const firstStep = response.data.url;
            window.location.href = firstStep;
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        handleSignIn();
    }, []);

    return <Loader />;
};

export default Auth;
