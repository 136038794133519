import React, { useContext, useEffect, useState } from 'react';
import { destroySessionById, fetchActiveSessions } from '../../API/Session';
import { Badge, Button, Table } from 'react-bootstrap';
import { ISession } from '../../interfaces/Session';
import { useHistory } from 'react-router-dom';
import { EmployeeContext } from '../../GoalsRoutes';
import './ActiveSessions.scss';
import ActiveSessionsSkeleton from '../../Skeletons/ActiveSessionsSkeleton';
import { onLogout } from '../../API/Admin';
import ConfirmationModal from './ConfirmationModal';
import { Loader } from '../../components';

const ActiveSessions: React.FC = () => {
    const [sessions, setSessions] = useState<ISession[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showModal, handleShowModal] = useState<boolean>(false);
    const [sessionToBeDestroyed, setSessionToBeDestroyed] = useState<ISession | null>(null);
    const employee = useContext(EmployeeContext);
    const userId = employee.employeeId;
    const history = useHistory();
    const destroySession = (session: ISession) => {
        setSessionToBeDestroyed(session);
        handleShowModal(true);
    };
    const closeModal = () => {
        handleShowModal(false);
        setSessionToBeDestroyed(null);
    };
    useEffect(() => {
        document.body.classList.add('active-sessions-body');
        return () => {
            document.body.classList.remove('active-sessions-body');
        };
    }, []);
    useEffect(() => {
        const loadSessions = async () => {
            setLoading(true);
            const activeSessions = await fetchActiveSessions(userId);
            if (activeSessions.length === 1) await handleContinueSession();
            else {
                setSessions(activeSessions);
                setLoading(false);
            }
        };
        loadSessions();
    }, [userId]);
    const handleDestroy = async (session: ISession) => {
        if (session.isCurrent) await onLogout();
        const { sessionId } = session;
        const success = await destroySessionById(sessionId);
        if (success) {
            setSessions((prevSessions) => {
                const updatedSessions = prevSessions.filter((session) => session.sessionId !== sessionId);
                return updatedSessions;
            });
            closeModal();
        }
    };
    const handleContinueSession = async () => {
        history.push('/dashboard');
    };
    if (loading) return <Loader />;
    return (
        <div className="active-sessions">
            <div className="active-sessions-header">
                <h3>Active Sessions</h3>
                <Button variant="primary" onClick={handleContinueSession} disabled={loading}>
                    Continue Session
                </Button>
            </div>

            <Table className="active-sessions-table" striped bordered hover>
                <thead>
                    <tr>
                        <th>IP Address</th>
                        <th>Browser</th>
                        <th>Login Time</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sessions.map((session) => (
                        <tr key={session.sessionId}>
                            <td>{session.ipAddress}</td>
                            <td>
                                {session.browser}
                                {session.isCurrent && (
                                    <Badge pill className="btn-success">
                                        Current
                                    </Badge>
                                )}
                            </td>
                            <td>{new Date(session.loginTime).toLocaleString()}</td>
                            <td>
                                <div className="action-buttons">
                                    <Button
                                        variant="primary"
                                        className="destroy"
                                        onClick={() => destroySession(session)}
                                    >
                                        Destroy
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {showModal && sessionToBeDestroyed && (
                <ConfirmationModal
                    show={showModal}
                    session={sessionToBeDestroyed}
                    handleDestroy={handleDestroy}
                    closeModal={closeModal}
                />
            )}
        </div>
    );
};

export default ActiveSessions;
