import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ISession } from '../../interfaces/Session';
interface Props {
    session: ISession;
    handleDestroy: (session: ISession) => void;
    closeModal: () => void;
    show: boolean;
}
const ConfirmationModal: React.FC<Props> = ({ session, handleDestroy, closeModal, show }) => {
    return (
        <>
            <Modal show={show} onHide={closeModal} className="confirmation-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete Session</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => {
                            handleDestroy(session);
                        }}
                    >
                        Yes
                    </Button>
                    <Button variant="outline-secondary" onClick={closeModal}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ConfirmationModal;
