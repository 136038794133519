import axios from 'axios';
import { IOwnerSelectFilter, ISelectFilter, IStatusWithResponse } from '../interfaces';
import { getAPIStatus, reloadPageForUnauthorized } from '../utils';
const onLogout = async () => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_HOST}/v1/auth/logout`, null, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            withCredentials: true,
        });
        document.cookie.split(';').forEach((c) => {
            document.cookie = c.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
        });
        localStorage.clear();
        window.location.assign(`${process.env.REACT_APP_LOG_OUT_REDIRECT}`);
    } catch (err) {
        console.log(err);
    }
};

const userAutoSearch = async (query: string, role = ''): Promise<ISelectFilter[] | undefined> => {
    try {
        const usersObj = await fetch(`${process.env.REACT_APP_API_HOST}/v1/users/search/?query=${query}&role=${role}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        reloadPageForUnauthorized(usersObj);
        const usersResponse = await usersObj.json();
        const usersList: IOwnerSelectFilter[] = [];

        for (const user of usersResponse) {
            usersList.push({
                label: user.email,
                value: user.id,
                email: user.email,
            });
        }

        return usersList;
    } catch (err) {
        console.log(err);
    }
};

const submitManagerChange = async (
    employeeId: number,
    managerId: number,
    quarter: string,
    year: number,
): Promise<IStatusWithResponse | undefined> => {
    try {
        const requestOptions = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        const res = await fetch(
            `${process.env.REACT_APP_API_HOST}/v1/admin/update-manager?employeeId=${employeeId}&managerId=${managerId}&quarter=${quarter}&year=${year}`,
            requestOptions,
        );
        reloadPageForUnauthorized(res);
        return { response: res, status: getAPIStatus(res.status) };
    } catch (err) {
        console.log(err);
    }
};

const submitRevertCompletedGoal = async (
    employeeId: number,
    quarter: string,
    year: number,
): Promise<IStatusWithResponse | undefined> => {
    try {
        const requestOptions = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        const res = await fetch(
            `${process.env.REACT_APP_API_HOST}/v1/admin/revert-completed-goal?employeeId=${employeeId}&quarter=${quarter}&year=${year}`,
            requestOptions,
        );
        reloadPageForUnauthorized(res);
        return { response: res, status: getAPIStatus(res.status) };
    } catch (err) {
        console.log(err);
    }
};

const submitDbEmplyeeSync = async (): Promise<IStatusWithResponse | undefined> => {
    try {
        const requestOptions = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/v1/admin/DB-EmployeeSync`, requestOptions);
        reloadPageForUnauthorized(res);
        return { response: res, status: getAPIStatus(res.status) };
    } catch (err) {
        console.log(err);
    }
};

const submitBatchEmplyeeSync = async (): Promise<IStatusWithResponse | undefined> => {
    try {
        const requestOptions = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/v1/admin/BatchEmployeeSync`, requestOptions);
        reloadPageForUnauthorized(res);
        return { response: res, status: getAPIStatus(res.status) };
    } catch (err) {
        console.log(err);
    }
};

const batchNewEmployeeAddtoCycle = async (): Promise<IStatusWithResponse | undefined> => {
    try {
        const requestOptions = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        const res = await fetch(
            `${process.env.REACT_APP_API_HOST}/v1/admin/BatchNewEmployeeAddToCycle`,
            requestOptions,
        );
        reloadPageForUnauthorized(res);
        return { response: res, status: getAPIStatus(res.status) };
    } catch (err) {
        console.log(err);
    }
};

export {
    userAutoSearch,
    submitManagerChange,
    submitRevertCompletedGoal,
    submitDbEmplyeeSync,
    submitBatchEmplyeeSync,
    batchNewEmployeeAddtoCycle,
    onLogout,
};
